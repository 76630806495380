(function () {

  window.onload = function() {

    $('.js-hol').each(function (i) {
      $(this).delay(i * 350).queue(function () {
        $(this).addClass('is-fire').dequeue();
        $(this).addClass('is-line').dequeue();
      });
    })
  }
})();

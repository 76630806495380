(function () {

  window.onload = function() {

    $('.js-hol').each(function (i) {
      $(this).delay(i * 350).queue(function () {
        $(this).addClass('is-fire').dequeue();
        $(this).addClass('is-line').dequeue();
      });
    })

    if ($('.js-slick-kv').length) {
      console.log('slick');
      $('.js-slick-kv').slick({
      dots: false,
      slidesToShow: 2,
      });
    }
  }
})();
